import { createRouter, createWebHistory } from 'vue-router';
import { setupI18n, loadLocaleMessages, SUPPORT_LOCALES } from './i18n';
import Home from './components/Home.vue';
import Tickets from './components/Tickets.vue';
import Tournament from './components/Tournament.vue';
import Imprint from './components/Imprint.vue';
import Dataprotection from './components/Dataprotection.vue';
import History from './components/History.vue';

const i18n = setupI18n();

const routes = [
    {
        path: '/',
        redirect: `/${i18n.global.locale.value}`,
        meta: {
            title: 'nav.home'
        }
    },
    {
        path: '/:locale',
        name: 'home',
        component: Home,
        meta: {
            title: 'nav.home'
        }
    },
    {
        path: '/:locale/travel',
        name: 'travel',
        component: Tickets,
        meta: {
            title: 'nav.travel'
        }
    },
    {
        path: '/:locale/tournament',
        name: 'tournament',
        component: Tournament,
        meta: {
            title: 'nav.games'
        }
    },
    {
        path: '/:locale/imprint',
        name: 'imprint',
        component: Imprint,
        meta: {
            title: 'footer.imprint'
        }
    },
    {
        path: '/:locale/dataprotection',
        name: 'dataprotection',
        component: Dataprotection,
        meta: {
            title: 'footer.privacy'
        }
    },
    {
        path: '/:locale/history',
        name: 'history',
        component: History,
        meta: {
            title: 'nav.info'
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return { top: 0 }
    },
});

// navigation guards
router.beforeEach(async (to, from, next) => {
    const paramsLocale = to.params.locale

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
        return next(`/${locale}`)
    }
    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
        await loadLocaleMessages(i18n, paramsLocale, to.meta.title)
    }
    document.title = setTitle(to.meta.title);
    return next()
})

router.afterEach((to, from) => {
    const lang = to.params.locale;
    if (i18n.global.locale.value !== lang) {
        i18n.global.locale.value = lang;
    }
    document.title = setTitle(to.meta.title, lang);
});

function setTitle(key, lang) {
    let title = 'Mercedes Benz JuniorCup 2024';
    const text = i18n.global.t(key) !== key ? i18n.global.t(key) : '';
    title = `${text}${text ? ' - ' : ''}${title}`;
    return title;
}

export default router;
