<template>
  <div class="tickets">
    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <h1 class="font-serif font-black text-3xl md:text-5xl uppercase mb-10 mt-0">
          {{ $t('imprint.title') }}
        </h1>
        <div class="prone mb-10">
          <p>
            <b>Mercedes-Benz Group AG</b><br/>
            Mercedesstraße 120<br/>
            70372 Stuttgart<br/>
            Deutschland<br/>
          </p>
          <p>
            {{ $t('imprint.commercial_register') }}
          </p>
          <p>
            <b>{{ $t('imprint.represent') }}</b><br/>
            {{ $t('imprint.represent_who') }}
          </p>
          <p>
            {{ $t('imprint.represent_boss') }}
          </p>
          <p>
            <b>{{ $t('imprint.phone') }}</b>: +49 711 17 - 0<br/>
            <b>{{ $t('imprint.mail') }}</b>: dialog@mercedes-benz.com
          </p>
          <p>
            {{ $t('imprint.ustid') }}
          </p>
          <p>
            <span class="font-serif font-bold uppercase">{{ $t('imprint.contact') }}</span><br/>
            Mercedes-Benz AG<br/>
            Orga-Team Mercedes-Benz JuniorCup<br/>
            {{ $t('imprint.mail') }}: juniorcup@mercedes-benz.com
          </p>
        </div>
      </div>
    </section>
    <section class="pt-20 pb-20 text-sm bg-slate-200 text-slate-600">
      <div class="container-small px-5 xl:px-0 prone">
        <h2 class="text-2xl uppercase font-serif font-bold mb-5">{{ $t('imprint.dispute_title')}}</h2>
        <p v-html="$t('imprint.dispute_text')"></p>
      </div>
    </section>
    <section class="pt-20 pb-20 text-sm text-slate-600" v-if="$i18n.locale === 'de'">
      <div class="container-small px-5 xl:px-0 prone">
        <h2 class="text-2xl uppercase font-serif font-bold mb-5">Rechtliche Hinweise</h2>
        <h3 class="font-serif text-base uppercase font-bold mb-5 mt-10">Geistiges Eigentum</h3>
        <p>
          Im Rahmen der Nutzung dieser Webseite ist das geistige Eigentum (insbesondere Urheber-, Marken-, Namens- und Patentrechte) der Mercedes-Benz Group oder Dritter zu beachten. Das Abrufen der Webseite gewährt keine Lizenz- oder Nutzungsrechte an dem jeweiligen geistigen Eigentum der Mercedes-Benz Group oder Dritter.
        </p>
        <h3 class="font-serif text-base uppercase font-bold mb-5 mt-10">Vorausschauende Aussagen</h3>
        <p>
          Diese Webseite enthält vorausschauende Aussagen zu unserer aktuellen Einschätzung künftiger Vorgänge. Wörter wie »antizipieren«, »annehmen«, »glauben«, »einschätzen«, »erwarten«, »beabsichtigen«, »können/könnten«, »planen«, »projizieren«, »sollten« und ähnliche Begriffe kennzeichnen solche vorausschauenden Aussagen.
        </p>
        <p>
          Diese Aussagen sind einer Reihe von Risiken und Unsicherheiten unterworfen. Einige Beispiele hierfür sind
          <ul class="ml-5 list-outside list-disc">
            <li>eine ungünstige Entwicklung der weltwirtschaftlichen Situation, insbesondere ein Rückgang der Nachfrage in unseren wichtigsten Absatzmärkten,</li>
            <li>eine Verschlechterung unserer Refinanzierungsmöglichkeiten an den Kredit- und Finanzmärkten,</li>
            <li>unabwendbare Ereignisse höherer Gewalt wie beispielsweise Naturkatastrophen, Pandemien, Terrorakte, politische Unruhen, kriegerische Auseinandersetzungen, Industrieunfälle und deren Folgewirkungen auf unsere Verkaufs-, Einkaufs-, Produktions- oder Finanzierungsaktivitäten,</li>
            <li>Veränderungen von Wechselkursen, Zoll- und Außenhandelsbestimmungen,</li>
            <li>eine Veränderung des Konsumverhaltens in Richtung kleinerer und weniger gewinnbringender Fahrzeuge, eine eingeschränkte Nachfrage nach batterieelektrischen Fahrzeugen oder ein möglicher Akzeptanzverlust unserer Produkte und Dienstleistungen mit der Folge einer Beeinträchtigung bei der Durchsetzung von Preisen und bei der Auslastung von Produktionskapazitäten,</li>
            <li>Preiserhöhungen bei Kraftstoffen, Rohstoffen und Energie,</li>
            <li>Unterbrechungen der Produktion aufgrund von Material- oder Energieengpässen, Belegschaftsstreiks oder Lieferanteninsolvenzen,</li>
            <li>ein Rückgang der Wiederverkaufspreise von Gebrauchtfahrzeugen,</li>
            <li>die erfolgreiche Umsetzung von Kostenreduzierungs- und Effizienzsteigerungsmaßnahmen,</li>
            <li>die Geschäftsaussichten der Gesellschaften, an denen wir bedeutende Beteiligungen halten,</li>
            <li>die erfolgreiche Umsetzung strategischer Kooperationen und Joint Ventures,</li>
            <li>die Änderungen von Gesetzen, Bestimmungen und behördlichen Richtlinien, (oder Änderungen in deren Auslegung), insbesondere soweit sie Fahrzeugemissionen, Kraftstoffverbrauch und Sicherheit oder die Berichterstattung zu ESG-Themen (Umwelt-, Sozial-, und Governance-Themen) betreffen,</li>
            <li>sowie der Abschluss laufender behördlicher oder von Behörden veranlasster Untersuchungen und der Ausgang anhängiger oder drohender künftiger rechtlicher Verfahren</li>
            <li>und weitere Risiken und Unwägbarkeiten, von denen einige im aktuellen Geschäftsbericht unter der Überschrift »Risiko- und Chancenbericht« beschrieben sind.</li>
          </ul>
        </p>
        <p>
          Sollte einer dieser Unsicherheitsfaktoren oder eine dieser Unwägbarkeiten eintreten oder sollten sich die den vorausschauenden Aussagen zugrundeliegenden Annahmen als unrichtig erweisen, könnten die tatsächlichen Ergebnisse wesentlich von den in diesen Aussagen genannten oder implizit zum Ausdruck gebrachten Ergebnissen abweichen.
        </p>
        <p>
          Wir haben weder die Absicht noch übernehmen wir eine Verpflichtung, vorausschauende Aussagen laufend zu aktualisieren, da diese ausschließlich auf den Umständen am Tag der Veröffentlichung basieren.
        </p>
      </div>
    </section>
    <section class="pt-20 pb-20 text-sm text-slate-600" v-if="$i18n.locale === 'en'">
      <div class="container-small px-5 xl:px-0 prone">
        <h2 class="text-2xl uppercase font-serif font-bold mb-5">Legal Notices</h2>
        <h3 class="font-serif text-base uppercase font-bold mb-5 mt-10">Intellectual Property</h3>
        <p>
          When using this website, it is important to respect the intellectual property (including copyright, trademark, name, and patent rights) of the Mercedes-Benz Group or third parties. Accessing the website does not grant any license or usage rights to the respective intellectual property of the Mercedes-Benz Group or third parties.
        </p>
        <h3 class="font-serif text-base uppercase font-bold mb-5 mt-10">Forward-Looking Statements</h3>
        <p>
          This website contains forward-looking statements regarding our current assessment of future events. Words such as "anticipate," "assume," "believe," "estimate," "expect," "intend," "may/might," "plan," "project," "should," and similar terms identify such forward-looking statements.
          <ul class="ml-5 list-outside list-disc">
            <li>These statements are subject to a number of risks and uncertainties. Some examples of these include:eine ungünstige Entwicklung der weltwirtschaftlichen Situation, insbesondere ein Rückgang der Nachfrage in unseren wichtigsten Absatzmärkten,</li>
            <li>An unfavorable development in the global economic situation, especially a decrease in demand in our key markets,</li>
            <li>Deterioration of our refinancing options in credit and financial markets,</li>
            <li>Unforeseeable events of force majeure, such as natural disasters, pandemics, acts of terrorism, political unrest, armed conflicts, industrial accidents, and their repercussions on our sales, procurement, production, or financing activities,</li>
            <li>Changes in exchange rates, customs, and trade regulations,</li>
            <li>A shift in consumer behavior towards smaller and less profitable vehicles, limited demand for battery-electric vehicles, or a potential loss of acceptance of our products and services, resulting in price pressure and underutilization of production capacity,</li>
            <li>Price increases in fuels, raw materials, and energy,</li>
            <li>Production disruptions due to material or energy shortages, labor strikes, or supplier insolvencies,</li>
            <li>A decrease in resale prices of used vehicles,</li>
            <li>Successful implementation of cost reduction and efficiency enhancement measures,</li>
            <li>Business prospects of companies in which we hold significant stakes,</li>
            <li>Successful implementation of strategic cooperations and joint ventures,</li>
            <li>Changes in laws, regulations, and regulatory guidelines (or changes in their interpretation), particularly concerning vehicle emissions, fuel consumption, safety, or reporting on ESG issues (Environmental, Social, and Governance issues),</li>
            <li>The conclusion of ongoing regulatory or authority-initiated investigations and the outcome of pending or prospective legal proceedings,</li>
            <li>And other risks and uncertainties, some of which are described in the current annual report under the heading "Risk and Opportunity Report."</li>
          </ul>
        </p>
        <p>
          If any of these uncertainty factors or uncertainties occur, or if the assumptions underlying the forward-looking statements prove to be incorrect, the actual results may significantly differ from the results stated or implied in these statements.
        </p>
        <p>
          We neither intend nor assume an obligation to continuously update forward-looking statements, as they are solely based on the circumstances at the time of publication.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import anime from 'animejs';
import Axios from 'axios';
// Import Swiper Vue.js components
// import { Swiper, SwiperSlide } from 'swiper/vue';
// import { EffectCreative } from "swiper";
// Import Swiper styles

export default {
  name: 'ImprintComponent',
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
    //
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
//
</style>
