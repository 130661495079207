<template>
  <div class="bg-white shadow-xl">
    <table class="w-full border-collapse hidden md:table">
      <thead class="text-xs">
      <th class="text-left uppercase">{{ $t('time') }}</th>
      <th class="uppercase">{{ $t('result.group') }}</th>
      <th class="uppercase" colspan="7">{{ $t('result.event') }}</th>
      </thead>
      <tbody>
        <template v-for="(line, index) in entries" :key="`timetable-${index}`">
          <tr v-if="line.isGame && groups.includes(line.group) && tournament === line.tournament">
            <td class="text-center">
              <span class="text-xs">{{ dateFormat(line.date) }}</span><br/><span class="font-bold text-sm">{{ hourFormat(line.date) }}</span>
            </td>
            <td style="width: 50px" class="text-sm">{{ line.text ? line.text : line.group }}</td>
            <td class="text-right font-bold"
                :class="{ 'text-red-700': !line.provisional && line.team1.goals > line.team2.goals, 'text-slate-500': !line.provisional && line.team1.goals < line.team2.goals }"
                v-html="line.team1.key && !teams[line.team1.key].placeholder ? teams[line.team1.key].name : line.team1.placeholder"></td>
            <td class="has-logo"><img :src="teams[line.team1.key].logo" v-if="line.team1.key && !teams[line.team1.key].placeholder"><span v-if="!line.team1.key">&nbsp;</span></td>
            <td style="width: 20px" class="text-3xl text-slate-500 font-bold text-right" v-html="!line.provisional ? line.team1.goals : '-'"></td>
            <td class="font-xl font-serif" style="width: 10px">:</td>
            <td style="width: 20px" class="text-3xl text-slate-500 font-bold text-left" v-html="!line.provisional ? line.team2.goals : '-'"></td>
            <td class="has-logo"><img :src="teams[line.team2.key].logo" v-if="line.team2.key && !teams[line.team2.key].placeholder"><span v-if="!line.team2.key">&nbsp;</span></td>
            <td class="text-left font-bold"
                :class="{ 'text-red-700': !line.provisional && line.goals1 < line.goals2, 'text-slate-500': !line.provisional && line.goals1 > line.goals2 }"
                v-html="line.team2.key && !teams[line.team2.key].placeholder ? teams[line.team2.key].name : line.team2.placeholder"></td>
          </tr>
          <tr v-if="!line.isGame && tournament === line.tournament && groups.includes(line.group)" class="dark">
            <td class="text-center">
              <span class="text-xs">{{ dateFormat(line.date) }}</span><br/><span class="font-bold text-sm">{{ hourFormat(line.date) }}</span>
            </td>
            <td class="text-sm" colspan="8" v-html="line.text"></td>
          </tr>
        </template>
      </tbody>
    </table>
    <table class="w-full border-collapse md:hidden">
      <thead class="text-xs">
        <th colspan="2" class="text-left uppercase">{{ $t('time') }}</th>
        <th colspan="6" class="uppercase text-left">{{ $t('result.event') }}</th>
      </thead>
      <tbody>
      <template v-for="(line, index) in entries" :key="`timetable-${index}`">
        <tr v-if="line.isGame && groups.includes(line.group) && tournament === line.tournament">
          <td class="text-left pb-0 text-slate-400"
              colspan="3"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !==  0 }">
            <span class="text-xs">{{ dateFormat(line.date) }}</span>, <span class="font-bold text-xs">{{ hourFormat(line.date) }}</span>
          </td>
          <td class="text-xs font-bold pb-0 text-right text-slate-400"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              colspan="4">{{ line.text ? line.text : `${$t('result.group')} ${ line.group }` }}</td>
        </tr>
        <tr v-if="line.isGame && groups.includes(line.group) && tournament === line.tournament" class="text-sm">
          <td class="text-left font-bold"
              :class="{ 'text-red-700': !line.provisional && line.team1.goals > line.team2.goals, 'text-slate-500': !line.provisional && line.team1.goals < line.team2.goals, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              v-html="line.team1.key && !teams[line.team1.key].placeholder ? teams[line.team1.key].short : line.team1.placeholder"></td>
          <td class="has-logo"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"><img :src="teams[line.team1.key].logo" v-if="line.team1.key && !teams[line.team1.key].placeholder"><span v-if="!line.team1.key">&nbsp;</span></td>
          <td style="width: 20px"
              :class="{ 'text-red-700': !line.provisional && line.team1.goals > line.team2.goals, 'text-slate-500': !line.provisional && line.team1.goals < line.team2.goals, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              class="font-bold text-right"
              v-html="!line.provisional ? line.team1.goals : '-'"></td>
          <td class="font-serif"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              style="width: 10px">:</td>
          <td style="width: 20px"
              class="font-bold text-left"
              :class="{ 'text-red-700': !line.provisional && line.team1.goals < line.team2.goals, 'text-slate-500': !line.provisional && line.team1.goals > line.team2.goals, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              v-html="!line.provisional ? line.team2.goals : '-'"></td>
          <td class="has-logo"
              :class="{ 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"><img :src="teams[line.team2.key].logo" v-if="line.team2.key && !teams[line.team2.key].placeholder"><span v-if="!line.team2.key">&nbsp;</span></td>
          <td class="text-right font-bold"
              :class="{ 'text-red-700': !line.provisional && line.team1.goals < line.team2.goals, 'text-slate-500': !line.provisional && line.team1.goals > line.team2.goals, 'bg-white': index % 2 === 0, 'bg-slate-100': index % 2 !== 0 }"
              v-html="line.team2.key && !teams[line.team2.key].placeholder ? teams[line.team2.key].short : line.team2.placeholder"></td>
        </tr>
        <tr v-if="!line.isGame && tournament === line.tournament && groups.includes(line.group)" class="dark">
          <td class="text-left text-white" colspan="7">
            <div class="text-xs mb-1">{{ dateFormat(line.date) }}, <span class="font-bold">{{ hourFormat(line.date) }}</span></div>
            <div class="text-sm" v-html="line.text"></div>
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'TimetableComponent',
  components: {},
  props: {
    entries: Array,
    teams: Object,
    groups: Array,
    tournament: {
      type: String,
      default: 'profi'
    }
  },
  data() {
    return {}
  },
  methods: {
    dateFormat: function (input) {
      const date = new Date(input);
      return `${(`00${date.getDate()}`).slice(-2)}.${(`00${date.getMonth() + 1}`).slice(-2)}.${date.getFullYear()}`;
    },
    hourFormat: function (input) {
      const date = new Date(input);
      return `${(`00${date.getHours()}`).slice(-2)}:${(`00${date.getMinutes()}`).slice(-2)}`;
    }
  },
  created() {},
  mounted() {},
  unmounted() {}
};
</script>

<style lang="scss">
td {
  &.has-logo, &:last-child.has-logo, &:first-child.has-logo {
    width: 50px;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
