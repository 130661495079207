<template>
  <div class="bg-white shadow-2xl">
    <div class="font-black uppercase p-5 text-xl">{{ $t('result.group') }} {{ groupName }}</div>
    <table class="w-full border-collapse">
      <thead>
      <th class="text-xs">&nbsp;</th>
      <th class="text-xs">&nbsp;</th>
      <th class="text-xs text-left" v-html="$t('result.team')"></th>
      <th v-if="groupName !== 'FINAL'" class="text-xs" v-html="$t('result.tableGames')"></th>
      <th v-if="groupName !== 'FINAL'" class="text-xs" v-html="$t('result.tableDiff')"></th>
      <th v-if="groupName !== 'FINAL'" class="text-xs text-right" v-html="$t('result.tablePoints')"></th>
      </thead>
      <tbody v-if="groups[tournament] && groups[tournament][groupName]">
      <tr v-for="(line, index) in groups[tournament][groupName]['teams']" :key="`grp-${index}`" class=" text-sm md:text-default">
        <td :class="{ 'text-xl font-bold': groupName === 'FINAL' }">{{ index + 1 }}</td>
        <td class="has-logo" style="width: 40px"><img
            :src="teams[line.key].logo"
            v-if="line.key && !teams[line.key].placeholder"><span v-if="!line.key" v-html="line.placeholder || '&nbsp'"></span></td>
        <td :class="{ 'text-slate-400': line.provisional, 'text-xl': groupName === 'FINAL', 'font-bold': groupName !== 'FINAL' }">
          {{ line.key && !teams[line.key].placeholder ? teams[line.key].name : line.placeholder }}
        </td>
        <td v-if="groupName !== 'FINAL'" class="text-center" v-html="line.key && !teams[line.key].placeholder ? line.games : '&nbsp;'"></td>
        <td v-if="groupName !== 'FINAL'" class="text-center">{{ line.key && !teams[line.key].placeholder ? (line.goals - line.received >= 0 ? '+' : '') : '&nbsp;' }}{{ line.key && !teams[line.key].placeholder ? line.goals - line.received : '' }}</td>
        <td v-if="groupName !== 'FINAL'" class="text-right font-bold" v-html="line.key && !teams[line.key].placeholder ? line.points : '&nbsp;'"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'TableComponent',
  components: {},
  props: {
    groupName: String,
    groups: Object,
    teams: Object,
    tournament: {
      type: String,
      default: 'profi'
    },
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  unmounted() {}
};
</script>

<style lang="scss">
</style>
