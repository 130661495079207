<template>
  <div class="dataprotection">
    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0 prone" v-if="$i18n.locale === 'de'">
        <h1>Datenschutz&shy;erkl&auml;rung</h1>

        <p>
        <b>Verantwortlicher im Sinne der EU-Datenschutz-Grundverordnung (DSGVO) ist:</b><br/>
        Mercedes-Benz AG („Wir“)<br/>
        Mercedesstraße 120, 70372 Stuttgart, Deutschland<br/>
        E-Mail: juniorcup@mercedes-benz.com
        </p>
        <p>
        <b>Datenschutzbeauftragter:</b><br/>
        Mercedes-Benz Group AG<br/>
        Konzernbeauftragter für den Datenschutz<br/>
        HPC E600, 70546 Stuttgart, Deutschland<br/>
        E-Mail: data.protection@mercedes-benz.com
        </p>

        <h2>1. Datenschutz</h2>

        Wir freuen uns über Ihren Besuch auf unseren Webseiten und Ihr Interesse an unseren Angeboten. Der Schutz Ihrer personenbezogenen Daten ist für uns ein wichtiges Anliegen. In diesen Datenschutzhinweisen erklären wir, wie wir Ihre personenbezogenen Daten erheben, was wir damit tun, für welche Zwecke und auf welchen Rechtsgrundlagen dies geschieht, und welche Rechte und Ansprüche sich damit für Sie verbinden. Zusätzlich verweisen wir auf die Mercedes-Benz-Datenschutzrichtlinie: Mercedes-Benz Datenschutzrichtlinie.

        Unsere Datenschutzhinweise für den Gebrauch unserer Webseiten und die Datenschutzrichtlinie der Mercedes-Benz gelten nicht für Ihre Aktivitäten auf den Webseiten von sozialen Netzwerken oder anderen Anbietern, die Sie über die Links auf unseren Webseiten erreichen können. Bitte informieren Sie sich auf den Webseiten dieser Anbieter über deren Datenschutzbestimmungen.

        <h2>2. Erhebung und Verarbeitung Ihrer personenbezogenen Daten</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
        <li>Wenn Sie unsere Webseiten besuchen, speichern wir bestimmte Angaben zu dem von Ihnen verwendeten Browser und Betriebssystem, das Datum und die Uhrzeit des Besuches, den Zugriffsstatus (z.B. ob Sie eine Webseite aufrufen konnten oder eine Fehlermeldung erhielten), die Nutzung von Funktionen der Webseite, die von Ihnen möglicherweise eingegebenen Suchbegriffe, die Häufigkeit, mit der Sie einzelne Webseiten aufrufen, die Bezeichnung abgerufener Dateien, die übertragenen Datenmenge, die Webseite, von der aus Sie auf unsere Webseiten gelangt sind, und die Webseite, die Sie von unseren Webseiten aus besuchen, sei es, indem Sie Links auf unseren Webseiten anklicken oder eine Domain direkt in das Eingabefeld derselben Registerkarte (bzw. desselben Fensters) Ihres Browsers eingeben, worin Sie unsere Webseiten geöffnet haben. Außerdem speichern wir aus Sicherheitsgründen, insbesondere zur Vorbeugung vor und Erkennung von Angriffen auf unsere Webseiten oder Betrugsversuchen, für die Dauer von sieben Tagen Ihre IP-Adresse und den Namen Ihres Internet Service Providers.</li>
        <li>Andere personenbezogene Daten speichern wir nur, falls Sie uns diese Daten z.B. im Rahmen einer Registrierung, eines Kontaktformulars, einer Umfrage, eines Preisausschreibens oder zur Durchführung eines Vertrages mitteilen, und auch in diesen Fällen nur, soweit uns dies aufgrund einer von Ihnen erteilten Einwilligung oder nach den geltenden Rechtsvorschriften (weitere Informationen dazu finden Sie unten im Abschnitt „Rechtsgrundlagen der Verarbeitung“) gestattet ist.</li>
        <li>Sie sind weder gesetzlich noch vertraglich verpflichtet, Ihre personenbezogenen Daten zu überlassen. Möglich ist aber, dass bestimmte Funktionen unserer Webseiten von der Überlassung personenbezogener Daten abhängen. Falls Sie in diesen Fällen personenbezogene Daten nicht überlassen, kann dies dazu führen, dass Funktionen nicht oder nur eingeschränkt zur Verfügung stehen.</li>
        </ol>
        <h2>3. Nutzungszwecke</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>Die bei einem Besuch unserer Webseiten erhobenen personenbezogenen Daten verwenden wir, um diese für Sie möglichst komfortabel zu betreiben sowie unsere IT-Systeme vor Angriffen und anderen rechtswidrigen Handlungen zu schützen.</li>
          <li>Soweit Sie uns weitere personenbezogene Daten z.B. im Rahmen einer Registrierung, eines Kontaktformulars, einer Umfrage, eines Preisausschreibens oder zur Durchführung eines Vertrages mitteilen, nutzen wir diese Daten zu den genannten Zwecken, zu Zwecken der Kundenverwaltung und – soweit erforderlich – zu Zwecken der Abwicklung und Abrechnung etwaiger Geschäftsvorgänge, jeweils in dem dafür erforderlichen Umfang.</li>
        </ol>

        <h2>4. Übertragung personenbezogener Daten an Dritte; Social Plug-ins; Einsatz von Dienstleistern</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>Unsere Webseiten können auch Angebote Dritter enthalten. Wenn Sie ein solches Angebot anklicken, übertragen wir im erforderlichen Umfang Daten an den jeweiligen Anbieter (z. B. die Angabe, dass Sie dieses Angebot bei uns gefunden haben und ggf. weitere Informationen, die Sie hierfür auf unseren Webseiten bereits angegeben haben). </li>
          <li>
            <p>Wenn wir auf unseren Webseiten sogenannte „Social Plug-ins“ sozialer Netzwerke wie Facebook und Twitter einsetzen, binden wir diese wie folgt ein:</p>
            <p>Wenn Sie unsere Webseiten besuchen, sind die Social Plug-ins deaktiviert, d.h. es findet keine Übertragung irgendwelcher Daten an die Betreiber dieser Netzwerke statt. Falls Sie eines der Netzwerke nutzen möchten, klicken Sie auf das jeweilige Social Plug-in, um eine direkte Verbindung mit dem Server des jeweiligen Netzwerks aufzubauen.</p>

            <p>Falls Sie bei dem Netzwerk ein Nutzerkonto haben und im Moment des Aktivierens des Social Plug-ins dort eingeloggt sind, kann das Netzwerk Ihren Besuch der unserer Webseiten Ihrem Nutzerkonto zuordnen. Wenn Sie das vermeiden möchten, loggen Sie sich bitte vor der Aktivierung des Social Plug-ins aus dem Netzwerk aus. Den Besuch anderer Mercedes-Benz-Webseiten kann ein soziales Netzwerk nicht zuordnen, bevor Sie nicht auch ein dort vorhandenes Social Plug-in aktiviert haben.</p>

            <p>Wenn Sie ein Social Plug-in aktivieren, überträgt das Netzwerk die dadurch verfügbar werdenden Inhalte direkt an Ihren Browser, der sie in unsere Webseiten einbindet. In dieser Situation können auch Datenübertragungen stattfinden, die vom jeweiligen sozialen Netzwerk initiiert und gesteuert werden. Für Ihre Verbindung zu einem sozialen Netzwerk, die zwischen dem Netzwerk und Ihrem System stattfindenden Datenübertragungen und für Ihre Interaktionen auf dieser Plattform gelten ausschließlich die Datenschutzbestimmungen des jeweiligen Netzwerks.</p>

            <p>Das Social Plug-in bleibt aktiv, bis Sie es deaktivieren oder Ihre Cookies löschen.</p>
          </li>
          <li>Wenn Sie den Link zu einem Angebot anklicken oder ein Social Plug-in aktivieren, kann es sein, dass personenbezogene Daten zu Anbietern in Ländern außerhalb des Europäischen Wirtschaftsraums gelangen, die aus der Sicht der Europäischen Union („EU“) kein den EU-Standards entsprechendes „angemessenes Schutzniveau“ für die Verarbeitung personenbezogener Daten gewährleisten. Bitte denken Sie an diesen Umstand, bevor Sie einen Link anklicken oder ein Social Plug-in aktivieren und damit eine Übertragung Ihrer Daten auslösen.</li>
          <li>Für Betrieb, Optimierung und Absicherung unserer Webseiten setzen wir außerdem qualifizierte Dienstleister (z. B. IT-Dienstleister, Marketing-Agenturen) ein. Personenbezogene Daten geben wir an diese nur weiter, soweit dies erforderlich ist für die Bereitstellung und Nutzung der Webseiten und deren Funktionalitäten, zur Verfolgung berechtigter Interessen oder soweit Sie darin eingewilligt haben (s. unten im Abschnitt „Rechtsgrundlagen der Verarbeitung“).</li>
        </ol>
        <h2>5. Sicherheit</h2>

        <p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre durch uns verwalteten Daten gegen Manipulationen, Verlust, Zerstörung und gegen den Zugriff unberechtigter Personen zu schützen. Wir verbessern unsere Sicherheitsmaßnahmen fortlaufend entsprechend der technologischen Entwicklung.</p>

        <h2>6. Rechtsgrundlagen der Verarbeitung</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>Soweit Sie uns für die Verarbeitung Ihrer personenbezogenen Daten eine Einwilligung erteilt haben, stellte diese die Rechtsgrundlage für die Verarbeitung dar (Art. 6 Abs. 1 Buchst. a DSGVO).</li>
          <li>Für eine Verarbeitung personenbezogener Daten für die Zwecke der Anbahnung oder der Erfüllung eines Vertrages mit Ihnen ist Art. 6 Abs. 1 Buchst. b DSGVO die Rechtsgrundlage.</li>
          <li>Soweit die Verarbeitung Ihrer personenbezogenen Daten zur Erfüllung unserer rechtlichen Verpflichtungen (z.B. zur Aufbewahrung von Daten) erforderlich ist, sind wir dazu gemäß Art. 6 Abs. 1 Buchst. c DSGVO befugt.</li>
          <li>Außerdem verarbeiten wir personenbezogene Daten zu Zwecken der Wahrnehmung unserer berechtigten Interessen sowie berechtigter Interessen Dritter gemäß Art. 6 Abs. 1 Buchst. f DSGVO. Die Erhaltung der Funktionsfähigkeit unserer IT-Systeme, die (Direkt-)Vermarktung eigener und fremder Produkte und Dienstleistungen und die rechtlich gebotene Dokumentation von Geschäftskontakten sind solche berechtigten Interessen. Wir berücksichtigen im Rahmen der jeweils erforderlichen Interessenabwägung insbesondere die Art der personenbezogenen Daten, den Verarbeitungszweck, die Verarbeitungsumstände und Ihr Interesse an der Vertraulichkeit Ihrer personenbezogenen Daten.</li>
        </ol>

        <h2>7. Löschung Ihrer personenbezogenen Daten</h2>

        <p>Ihre IP-Adresse und den Namen Ihres Internet Service Providers, die wir nur aus Sicherheitsgründen speichern, löschen wir nach sieben Tagen. Im Übrigen löschen wir Ihre personenbezogenen Daten, sobald der Zweck, zu dem wir die Daten erhoben und verarbeitet haben, entfällt. Über diesen Zeitpunkt hinaus findet eine Speicherung nur statt, soweit dies gemäß den Gesetzen, Verordnungen oder sonstigen Rechtsvorschriften, denen wir unterliegen, in der EU oder nach Rechtsvorschriften in Drittstaaten, wenn dort jeweils ein angemessenes Datenschutzniveau gegeben ist, erforderlich ist. Soweit eine Löschung im Einzelfall nicht möglich ist, werden die entsprechenden personenbezogenen Daten mit dem Ziel markiert, ihre künftige Verarbeitung einzuschränken.</p>

        <h2>8. Betroffenenrechte</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>Als von der Datenverarbeitung betroffene Person haben Sie das Recht auf Auskunft (Art. 15 DSGVO), Berichtigung (Art. 16 DSGVO), Datenlöschung (Art. 17 DSGVO), Einschränkung der Verarbeitung (Art. 18 DSGVO) sowie Datenübertragbarkeit (Art. 20 DSGVO).</li>
          <li>Haben Sie in die Verarbeitung Ihrer personenbezogenen Daten durch uns eingewilligt, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der Verarbeitung Ihrer personenbezogenen Daten bis zu einem Widerruf wird durch den Widerruf nicht berührt. Ebenso unberührt bleibt eine weitere Verarbeitung dieser Daten aufgrund einer anderen Rechtsgrundlage, etwa zur Erfüllung rechtlicher Verpflichtungen (vgl. Abschnitt „Rechtsgrundlagen der Verarbeitung“).</li>
          <li>Widerspruchsrecht<br/>
              Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 e) DSGVO (Datenverarbeitung im öffentlichen Interesse) oder Art. 6 Abs. 1 f) DSGVO (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt, Widerspruch einzulegen. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nur weiter verarbeiten, soweit wir dafür zwingende berechtigte Gründe nachweisen können, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder soweit die Verarbeitung der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen dient. Sofern wir Ihre personenbezogenen Daten verarbeiten, um Direktwerbung zur Wahrnehmung berechtigter Interessen auf der Grundlage einer Interessenabwägung zu betreiben, haben Sie zudem das Recht, hiergegen jederzeit ohne Nennung von Gründen Widerspruch einzulegen.</li>
          <li>Wir bitten Sie, Ihre Ansprüche oder Erklärungen nach Möglichkeit an die nachstehende Kontaktadresse zu richten: juniorcup@mercedes-benz.com.</li>
          <li>Sind Sie der Ansicht, die Verarbeitung Ihrer personenbezogener Daten verstoße gegen gesetzliche Vorgaben, haben Sie das Recht zur Beschwerde bei einer zuständigen Datenschutzaufsichtsbehörde (Art. 77 DSGVO).</li>
        </ol>

        <h2>9. Newsletter</h2>

        <p>Wenn Sie einen auf unserer Webseite angebotenen Newsletter abonnieren, werden die bei der Newsletter-Anmeldung angegebenen Daten nur für den Versand des Newsletters verwendet, soweit Sie nicht einer weitergehenden Nutzung zustimmen. Sie können das Abonnement jederzeit über die im Newsletter vorgesehene Abmeldemöglichkeit beenden.</p>

        <h2>10. Zentraler Zugangsservice der Mercedes-Benz Group AG</h2>

        <p>Mit dem zentralen Zugangsservice der Mercedes-Benz Group AG können Sie sich bei allen an diesen Service angeschlossenen Webseiten und Applikationen der Mercedes-Benz-Gruppe und ihrer Marken anmelden. Die dafür geltenden Nutzungsbedingungen enthalten spezielle Datenschutzregelungen. Sie können diese Nutzungsbedingungen auf den jeweiligen Anmeldeseiten der angeschlossenen Webseiten und Applikationen abrufen.</p>

        <h2>11. Datenübertragung an Empfänger außerhalb des Europäischen Wirtschaftsraums</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>Beim Einsatz von Dienstleistern (s. Abschnitt „Einsatz von Dienstleistern“) können personenbezogene Daten an Empfänger in Ländern außerhalb der Europäischen Union („EU“), Islands, Liechtensteins und Norwegens (= Europäischer Wirtschaftsraum) übertragen und dort verarbeitet werden, insbesondere USA, Indien.</li>
          <li>In den folgenden Ländern besteht aus der Sicht der EU ein den EU-Standards entsprechendes angemessenes Schutzniveau für die Verarbeitung personenbezogener Daten (sog. Angemessenheitsbeschluss): Andorra, Argentinien, Kanada (eingeschränkt), Färöer-Inseln, Guernsey, Israel, Isle of Man, Japan, Jersey, Neuseeland, Schweiz, Süd-Korea, Uruguay, Vereinigtes Königreich. Mit Empfängern in anderen Ländern vereinbaren wir die Anwendung von EU-Standardvertragsklauseln, von verbindlichen Unternehmensregelungen oder andere zulässige Mechanismen, um entsprechend den gesetzlichen Anforderungen ein angemessenes Schutzniveau zu schaffen. Informationen hierzu stellen wir Ihnen gerne über die im Abschnitt „Betroffenenrechte“ genannten Kontaktdaten zur Verfügung.</li>
        </ol>

        <p>Stand: Januar 2022</p>
      </div>
      <div class="container-small px-5 xl:px-0 prone" v-if="$i18n.locale === 'en'">
        <h1>Privacy Policy</h1>

        <p>
          <b>Controller within the meaning of the EU General Data Protection Regulation (GDPR):</b><br/>
          Mercedes-Benz AG ("We")<br/>
          Mercedesstraße 120, 70372 Stuttgart, Germany<br/>
          Email: juniorcup@mercedes-benz.com
        </p>

        <p>
          <b>Data Protection Officer:</b><br/>
          Mercedes-Benz Group AG<br/>
          Group Data Protection Officer<br/>
          HPC E600, 70546 Stuttgart, Germany<br/>
          Email: data.protection@mercedes-benz.com
        </p>

        <h2>1. Data Protection</h2>

        <p>We welcome your visit to our websites and your interest in our offers. Protecting your personal data is important to us. In this data protection information, we explain how we collect your personal data, what we do with it, the purposes and legal bases for this processing, and the rights and claims associated with it. In addition, we refer to the Mercedes-Benz Data Protection Policy: Mercedes-Benz Data Protection Policy.</p>

        <p>Our data protection information for the use of our websites and the data protection policy of Mercedes-Benz do not apply to your activities on the websites of social networks or other providers that you can access through the links on our websites. Please familiarize yourself with the privacy policies of these providers on their websites.</p>

        <h2>2. Collection and Processing of Your Personal Data</h2>
        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>When you visit our websites, we store certain information about the browser and operating system you are using, the date and time of the visit, the access status (e.g., whether you could access a web page or received an error message), the use of website functions, any search terms you may have entered, the frequency with which you access individual web pages, the names of accessed files, the amount of data transferred, the website from which you reached our websites, and the website you visit from our websites, whether by clicking on links on our websites or by entering a domain directly into the input field of the same tab (or window) in your browser where you have our websites open. We also store your IP address and the name of your Internet Service Provider for security purposes, especially for the prevention and detection of attacks on our websites or fraudulent attempts, for a period of seven days.</li>
          <li>We only store other personal data if you provide us with such data, for example, in the context of registration, a contact form, a survey, a competition, or for the execution of a contract, and in these cases only to the extent that we have your consent or it is permitted by applicable law (further information can be found in the "Legal Bases for Processing" section below).</li>
          <li>You are neither legally nor contractually obligated to provide your personal data. However, certain features of our websites may depend on the provision of personal data. If you do not provide personal data in such cases, it may result in certain features not being available or being available only in a limited manner.</li>
        </ol>

        <h2>3. Purposes of Use</h2>
        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>We use the personal data collected during a visit to our websites to make them as convenient as possible for you and to protect our IT systems from attacks and other unlawful activities.</li>
          <li>If you provide us with additional personal data, such as in the context of registration, a contact form, a survey, a competition, or for the execution of a contract, we use this data for the purposes mentioned, for customer management, and, if necessary, for the execution and billing of any business transactions, each to the extent required for these purposes.</li>
        </ol>

        <h2>4. Transfer of Personal Data to Third Parties; Social Plug-ins; Use of Service Providers</h2>
        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>Our websites may also contain offers from third parties. If you click on such an offer, we will transfer data to the respective provider to the extent required (e.g., information that you found this offer with us and possibly other information that you have already provided on our websites).</li>
          <li>
            <p>If we use so-called "social plugins" of social networks such as Facebook and Twitter on our websites, we integrate them as follows:</p>
            <p>When you visit our websites, the social plugins are deactivated, meaning that no data is transferred to the operators of these networks. If you want to use one of the networks, click on the respective social plugin to establish a direct connection to the server of the respective network.</p>
            <p>If you have a user account with the network and are logged in at the moment you activate the social plugin, the network can associate your visit to our websites with your user account. If you want to avoid this, please log out of the network before activating the social plugin. A social network cannot associate your visit to other Mercedes-Benz websites until you have also activated a social plugin on those websites.
                When you activate a social plugin, the network makes the content available directly to your browser, which integrates it into our websites. In this situation, data transmissions initiated and controlled by the respective social network may also take place. For your connection to a social network, the data transfers that take place between the network and your system, and your interactions on that platform, only the privacy policies of the respective network apply.
                The social plugin remains active until you deactivate it or delete your cookies.</p>
          </li>
          <li>When you click on a link to an offer or activate a social plugin, it is possible that personal data may be transferred to providers in countries outside the European Economic Area (EEA) that, from the perspective of the European Union ("EU"), do not provide an "adequate level of data protection" in line with EU standards. Please consider this before clicking on a link or activating a social plugin, as it may trigger the transfer of your data.</li>
          <li>In addition to the operation, optimization, and protection of our websites, we also use qualified service providers (e.g., IT service providers, marketing agencies). We only disclose personal data to them to the extent necessary for the provision and use of the websites and their functionalities, the pursuit of legitimate interests, or if you have given your consent (see the "Legal Bases for Processing" section below).</li>
        </ol>

        <h2>5. Security</h2>

        <p>We implement technical and organizational security measures to protect the data we manage from manipulation, loss, destruction, and unauthorized access by third parties. We continuously improve our security measures in line with technological developments.</p>

        <h2>6. Legal Bases for Processing</h2>
        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>If you have given us consent for the processing of your personal data, that consent serves as the legal basis for the processing (Art. 6 para. 1 letter a GDPR).</li>
          <li>For the processing of personal data for the purpose of initiating or fulfilling a contract with you, Art. 6 para. 1 letter b GDPR is the legal basis.</li>
          <li>If the processing of your personal data is required to fulfill our legal obligations (e.g., data retention), we are authorized to do so under Art. 6 para. 1 letter c GDPR.</li>
          <li>We also process personal data for the purpose of pursuing our legitimate interests and the legitimate interests of third parties, in accordance with Art. 6 para. 1 letter f GDPR. Maintaining the functionality of our IT systems, (direct) marketing of our and third-party products and services, and the legally required documentation of business contacts are examples of such legitimate interests. When processing personal data, we consider the nature of the personal data, the purpose of processing, the circumstances of processing, and your interest in the confidentiality of your personal data, as part of the necessary balancing of interests.</li>
        </ol>

        <h2>7. Deletion of Your Personal Data</h2>

        <p>We delete your IP address and the name of your Internet Service Provider, which we only store for security reasons, after seven days. Furthermore, we delete your personal data as soon as the purpose for which we collected and processed the data no longer applies. Storage beyond this point only occurs when required by laws, regulations, or other legal provisions to which we are subject in the EU or by legal provisions in third countries that provide an adequate level of data protection. If deletion is not possible in individual cases, the corresponding personal data is marked to restrict its future processing.</p>

        <h2>8. Data Subject Rights</h2>

        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>As a data subject, you have the right to information (Art. 15 GDPR), rectification (Art. 16 GDPR), erasure (Art. 17 GDPR), restriction of processing (Art. 18 GDPR), and data portability (Art. 20 GDPR).</li>
          <li>If you have given consent for the processing of your personal data by us, you have the right to withdraw your consent at any time. The withdrawal of consent does not affect the lawfulness of processing based on consent before its withdrawal. Furthermore, the withdrawal does not affect further processing of this data on a different legal basis, such as to fulfill legal obligations (see "Legal Bases for Processing" section).</li>
          <li><p>Right to Object:</p>
            <p>You have the right to object at any time, for reasons arising from your particular situation, to the processing of your personal data based on Art. 6 para. 1 e) GDPR (data processing in the public interest) or Art. 6 para. 1 f) GDPR (data processing based on a balance of interests). If you object, we will only continue to process your personal data if we can demonstrate compelling legitimate grounds for the processing that outweigh your interests, rights, and freedoms, or if the processing serves to assert, exercise, or defend legal claims. If we process your personal data to conduct direct marketing as part of a balance of interests, you also have the right to object at any time without giving reasons.</p></li>
          <li>We kindly request that you address your claims or declarations to the following contact address, if possible: juniorcup@mercedes-benz.com.</li>
          <li>If you believe that the processing of your personal data violates legal requirements, you have the right to lodge a complaint with a competent data protection authority (Art. 77 GDPR).</li>
        </ol>

        <h2>9. Newsletter</h2>

        <p>If you subscribe to a newsletter offered on our website, the data provided during the newsletter registration will only be used to send the newsletter, unless you agree to further use. You can unsubscribe from the newsletter at any time using the unsubscribe option provided in the newsletter.</p>

        <h2>10. Central Access Service of Mercedes-Benz Group AG</h2>

        <p>With the central access service of Mercedes-Benz Group AG, you can log in to all websites and applications of the Mercedes-Benz Group and its brands that are connected to this service. Specific data protection regulations are included in the terms of use for this service. You can access these terms of use on the respective login pages of the connected websites and applications.</p>

        <h2>11. Transfer of Data to Recipients Outside the European Economic Area</h2>
        <ol class="list-outside mb-5 list-alpha pl-5">
          <li>When using service providers (see the "Use of Service Providers" section), personal data may be transferred to recipients in countries outside the European Union ("EU"), Iceland, Liechtenstein, and Norway (European Economic Area), including the United States and India.</li>
          <li>From the perspective of the EU, the following countries have an "adequate level of data protection" in line with EU standards (so-called adequacy decisions): Andorra, Argentina, Canada (limited), Faroe Islands, Guernsey, Israel, Isle of Man, Japan, Jersey, New Zealand, Switzerland, South Korea, Uruguay, United Kingdom. With recipients in other countries, we agree to the application of EU standard contractual clauses, binding corporate rules, or other permissible mechanisms to ensure an adequate level of data protection in accordance with legal requirements. We would be happy to provide you with information on this, using the contact information provided in the "Data Subject Rights" section.</li>
        </ol>

        <p>Effective Date: January 2022</p>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'DataprotectionComponent',
  components: {
    // Swiper,
    // SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {
    //
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
.dataprotection {
  h1 {
    @apply font-serif font-black text-3xl md:text-5xl uppercase mb-10 mt-10;
  }
  h2 {
    @apply font-serif font-black text-xl md:text-2xl uppercase mb-5 mt-10 text-red-700;
  }
  h3 {
    @apply font-bold text-xl uppercase mb-5 mt-0;
  }
  h4 {
    @apply font-bold;
  }
}
</style>
