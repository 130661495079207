import { createI18n } from 'vue-i18n'
import { nextTick } from 'vue'

export const SUPPORT_LOCALES = ['en', 'de'];

function getDefaultLang () {
  const navigatorLang = getNavigatorLang();
  const urlLang = getUrlLang();
  return urlLang || navigatorLang || 'en';
}

function getNavigatorLang () {
  let matched = null;
  SUPPORT_LOCALES.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang;
    }
  });
  if (!matched) {
    SUPPORT_LOCALES.forEach(lang => {
      const languagePartials = navigator.language.split('-')[0];
      if (lang === languagePartials) {
        matched = lang;
      }
    });
  }
  return matched;
}

function getUrlLang () {
  let matched = null;
  const localeFromUrl = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');
  if (localeFromUrl && localeFromUrl !== '/') {
    SUPPORT_LOCALES.forEach(lang => {
      if (localeFromUrl === lang) {
        matched = localeFromUrl;
      }
    });
  }
  return matched;
}

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
// function loadLocaleMessages() {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key).default
//     }
//   })
//   console.log(locales.keys());
//   return messages
// }

export async function loadLocaleMessages(i18n, locale, setHeader = '') {
  // load locale messages with dynamic import
  window.console.log('loadLocaleMessages', locale, setHeader);
  const messages = await import(
      /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
      )

  // set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default)
  if (setHeader) {
    document.title = `${i18n.global.t(to.meta.title)} - Mercedes Benz JuniorCup 2023`;
  }
  return nextTick()
}

export function setupI18n(options = { locale: 'en' }) {
  const i18n = createI18n({
    legacy: false,
    locale: getDefaultLang() || process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
    globalInjection: true
  });
  i18n.messages = loadLocaleMessages(i18n, i18n.global.locale.value);
  return i18n;
}
