<template>
  <div class="tickets">
    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <h1 class="font-serif font-black mb-5 mt-0">
          <div class="text-xl md:text-3xl">32. Mercedes-Benz JuniorCup</div>
          <div class="text-red-700 text-3xl md:text-5xl uppercase">{{ $t('result.mainEvent') }}</div>
        </h1>
        <div class="mb-10 font-black font-serif text-xl">{{ $t('result.presentedBy' ) }} VfB Stuttgart</div>

        <div class="grid grid-cols-2 items-start gap-3 mb-10">
          <div class="md:text-right md:col-span-1 col-span-2 font-bold">
            2 {{ $t('result.groups') }} á 4 {{ $t('result.teams')}}:
          </div>
          <div class="col-span-2 md:col-span-1" v-html="$t('result.roundsDescription')"></div>

          <div v-html="$t('result.gameDuration')" class="font-bold md:text-right"></div>
          <div>2 x 09:00 min</div>

          <div v-html="$t('result.gameHalftime')" class="font-bold md:text-right"></div>
          <div>02:00 min</div>

          <div v-html="$t('result.gameDurationFull')" class="font-bold md:text-right"></div>
          <div>20:00 min</div>

          <div v-html="$t('result.gameBreakPre')" class="font-bold md:text-right"></div>
          <div>02:00 min</div>

          <div v-html="$t('result.gameBreakFinal')" class="font-bold md:text-right"></div>
          <div>05:00 min</div>
        </div>
      </div>

      <div class="bg-slate-100 py-20">
        <div class="relative">
          <div class="sticky sticky-element">
            <div class="sticky-element__content bg-slate-100">
              <div class="container-small px-5 xl:px-0 overflow-hidden py-5">
                <div class="swiper-nav">
                  <div class="hidden md:block pointer-events-none pl-5">
                    <b>{{ $t('result.mainEvent') }}</b>
                  </div>
                  <div v-for="nav in profiSwiper.nav"
                       :key="`profiSwiper-${nav.target}`"
                       v-html="$t(nav.title)"
                       :class="{ 'active': profiSwiper.active === nav.target }"
                       @click="swiperGoto('profiSwiper', nav.target)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="overflow-hidden">
            <swiper
                id="profiSwiper"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :slides-per-view="1"
                :space-between="0"
                :auto-height="true"
                :grabCursor="true">
              <swiper-slide class="pt-10 pb-20 !overflow-visible">
                <div class="container-small px-5 xl:px-0">
                <div class="relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
                  <div>
                    <table-component :group-name="'A'"
                                     :groups="groups"
                                     :tournament="'profi'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                  <div>
                    <table-component :group-name="'B'"
                                     :groups="groups"
                                     :tournament="'profi'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                </div>
                <timetable-component :groups="['A', 'B']"
                                     :entries="timetable"
                                     :teams="teams"
                                     :tournament="'profi'"
                                     class="mt-10"
                                     v-if="loaded"></timetable-component>
                </div>
              </swiper-slide>
              <swiper-slide class="pt-10 pb-20 !overflow-visible">
                <div class="container-small px-5 xl:px-0">
                <div class="relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
                  <div>
                    <table-component :group-name="'1'"
                                     :groups="groups"
                                     :tournament="'profi'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                  <div>
                    <table-component :group-name="'2'"
                                     :groups="groups"
                                     :tournament="'profi'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                </div>
                <timetable-component :groups="['1', '2']"
                                     :entries="timetable"
                                     :teams="teams"
                                     :tournament="'profi'"
                                     class="mt-10"
                                     v-if="loaded"></timetable-component>
                </div>
              </swiper-slide>
              <swiper-slide class="pt-10 pb-20 !overflow-visible">
                <div class="container-small px-5 xl:px-0">
                <div class="relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
                  <div>
                    <table-component :group-name="'FINAL'"
                                     :groups="groups"
                                     :tournament="'profi'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                </div>
                <timetable-component :groups="['FINAL']"
                                     :entries="timetable"
                                     :teams="teams"
                                     :tournament="'profi'"
                                     class="mt-10"
                                     v-if="loaded"></timetable-component>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
    <section class="mt-20 mb-20">
      <div class="container-small px-5 xl:px-0">
        <h1 class="font-serif font-black text-3xl md:text-5xl uppercase mb-5 mt-0 text-red-700">
          {{ $t('result.amateurEvent') }}
        </h1>
        <div class="mb-10 font-black font-serif text-xl">{{ $t('result.presentedBy' ) }} VfB Stuttgart</div>

        <div class="grid grid-cols-2 items-start gap-3 mb-10">
          <div class="md:text-right md:col-span-1 col-span-2 font-bold">
            2 {{ $t('result.groups') }} á 4 {{ $t('result.teams')}}:
          </div>
          <div class="col-span-2 md:col-span-1" v-html="$t('result.roundsDescriptionAmateur')"></div>

          <div v-html="$t('result.gameDuration')" class="font-bold md:text-right"></div>
          <div>10:00 min</div>

          <div v-html="$t('result.gameBreak')" class="font-bold md:text-right"></div>
          <div>02:00 min</div>

          <div v-html="$t('result.gameDurationFinal')" class="font-bold md:text-right"></div>
          <div>2 x 09:00 min</div>

          <div v-html="$t('result.gameBreakFinalAmateur')" class="font-bold md:text-right"></div>
          <div>05:00 min</div>

          <div v-html="$t('result.gameHalftimeAmateur')" class="font-bold md:text-right"></div>
          <div>02:00 min</div>

          <div v-html="$t('result.gameDurationFinal')" class="font-bold md:text-right"></div>
          <div>20:00 min</div>
        </div>
      </div>

      <div class="bg-slate-100 py-20">
        <div class="relative">
          <div class="sticky sticky-element">
            <div class="sticky-element__content bg-slate-100">
              <div class="container-small px-5 xl:px-0 overflow-hidden py-5">
                <div class="swiper-nav">
                  <div class="hidden md:block pointer-events-none pl-5">
                    <b>{{ $t('result.amateurEvent') }}</b>
                  </div>
                  <div v-for="nav in amateurSwiper.nav"
                       :key="`amateurSwiper-${nav.target}`"
                       v-html="$t(nav.title)"
                       :class="{ 'active': amateurSwiper.active === nav.target }"
                       @click="swiperGoto('amateurSwiper', nav.target)"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="overflow-hidden">
            <swiper
                id="amateurSwiper"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                :slides-per-view="1"
                :space-between="0"
                :auto-height="true"
                :grabCursor="true">
              <swiper-slide class="pt-10 pb-20 !overflow-visible">
                <div class="container-small px-5 xl:px-0">
                <div class="relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
                  <div>
                    <table-component :group-name="'A'"
                                     :groups="groups"
                                     :tournament="'amateur'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                  <div>
                    <table-component :group-name="'B'"
                                     :groups="groups"
                                     :tournament="'amateur'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                </div>
                <timetable-component :groups="['A', 'B']"
                                     :entries="timetable"
                                     :teams="teams"
                                     :tournament="'amateur'"
                                     class="mt-10"
                                     v-if="loaded"></timetable-component>
                </div>
              </swiper-slide>
              <swiper-slide class="pt-10 pb-20 !overflow-visible">
                <div class="container-small px-5 xl:px-0">
                <div class="relative grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-20">
                  <div>
                    <table-component :group-name="'FINAL'"
                                     :groups="groups"
                                     :tournament="'amateur'"
                                     :teams="teams"
                                     v-if="loaded"></table-component>
                  </div>
                </div>
                <timetable-component :groups="['FINAL']"
                                     :entries="timetable"
                                     :teams="teams"
                                     :tournament="'amateur'"
                                     class="mt-10"
                                     v-if="loaded"></timetable-component>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import anime from 'animejs';
import Axios from 'axios';
import TableComponent from "@/components/helper/Table";
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import { EffectCreative } from "swiper";
import TimetableComponent from "@/components/helper/Timetable";
import axios from "axios";
// Import Swiper styles

export default {
  name: 'TournamentComponent',
  components: {
    TimetableComponent,
    TableComponent,
    Swiper,
    SwiperSlide,
  },
  props: {
    isSafari: Boolean,
    isMobile: Boolean,
  },
  data() {
    return {
      interval: null,
      modules: [EffectCreative],
      profiSwiper: {
        el: null,
        nav: [
          { title: 'result.roundOne', target: 0 },
          { title: 'result.roundTwo', target: 1 },
          { title: 'result.roundFinal', target: 2 }
        ],
        active: 0
      },
      amateurSwiper: {
        el: null,
        nav: [
          { title: 'result.roundOne', target: 0 },
          { title: 'result.roundFinal', target: 1 }
        ],
        active: 0
      },
      categories: [
        { number: 1, text: this.$t('tickets.category1text'), price: 25, classes: 'bg-red-700' }
      ],
      loaded: false,
      teams: {},
      tournaments: {},
      groups: {},
      timetable: []
    }
  },
  methods: {
    swiperGoto: function (swiper, slide) {
      if (this[swiper]) {
        this[swiper].el.slideTo(slide, 600);
      }
    },
    onSwiper: function (swiper) {
      const id = swiper.$el.attr('id');
      if (this[id]) {
        this[id].el = swiper;
        this[id].active = swiper.activeIndex;
      }
    },
    onSlideChange: function (swiper) {
      const id = swiper.$el.attr('id');
      if (this[id]) {
        this[id].active = swiper.activeIndex;
      }
    },
    fetch: async function () {
      const now = new Date();
      const timestamp = `${now.getFullYear()}${(`00${now.getMonth() + 1}`).slice(-2)}${(`00${now.getDate()}`).slice(-2)}${(`00${now.getHours()}`).slice(-2)}${(`00${now.getMinutes()}`).slice(-2)}`;
      // direct api call: https://cmsdata.net/mbjc-api/data/${this.$i18n.locale}
      // json file: https://cmsdata.net/static/mbjc_${this.$i18n.locale}.json?v=${now.now()/1000}
      await axios.get(`https://cmsdata.net/mbjc-api/data/${this.$i18n.locale}`, {  }).then((res) => {
        if (res.data && res.data.teams) {
          this.teams = res.data.teams;
          this.tournaments = res.data.tournaments;
          this.groups = res.data.groups;
          this.timetable = res.data.timetable;
          this.loaded = true;
          this.$nextTick(() => {
            this['profiSwiper'].el.update();
            this['amateurSwiper'].el.update();
          });
        }
      });
    }
  },
  mounted() {
    this.fetch();
    this.interval = setInterval(() => {
      this.fetch();
    }, 60 * 10000);
    this.observer = new IntersectionObserver(
        ([e]) => {
          // console.log(e.intersectionRatio);
          e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
        },
        {
          threshold: [1],
          rootMargin: '-65px 0px 0px 0px'
        }
    );
    this.observerMobile = new IntersectionObserver(
        ([e]) => {
          // console.log(e.intersectionRatio);
          e.target.classList.toggle('is-pinned-mobile', e.intersectionRatio < 1)
        },
        {
          threshold: [1],
          rootMargin: '-91px 0px 0px 0px'
        }
    );
    this.$nextTick(() => {
      const elements = document.getElementsByClassName('sticky');
      [...elements].forEach((el) => this.observer.observe(el));
      [...elements].forEach((el) => this.observerMobile.observe(el));
    });
  },
  unmounted() {
    clearInterval(this.interval);
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style lang="scss">
//
thead {
  @apply bg-slate-700 text-white;
}
th {
  @apply px-3 py-5 font-serif font-light;
  &:first-child { @apply pl-5; }
  &:last-child { @apply pr-5; }
}
tbody {
  tr {
    td {
      @apply px-3 py-2;
      &:first-child { @apply pl-5; }
      &:last-child { @apply pr-5; }
    }
  }
  tr:nth-child(even) {
    @apply bg-slate-100;
  }
  tr.dark {
    @apply bg-slate-500 text-white;
  }
  tr:nth-child(even).dark {
    @apply bg-slate-600;
  }
}
</style>
