import { createApp, defineAsyncComponent } from 'vue';
import App from './App.vue';
import store from './store';
// intersectionobserver
import SwObserver from './swobserver';
import { setupI18n } from './i18n';
import router from './router';

// style
import './styles/global.scss';
import 'swiper/css';
import "swiper/css/effect-creative";

const i18n = setupI18n();

// test settings
if (window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('demo.seitwerk.de') > -1) {
  document.querySelector('body').classList.add('test');
}

if (!('swobserver' in window)) {
  window.swobserver = new SwObserver({ rootMargin: '-150px 0px' });
  // scroll and mouse animationFrames
  window.swScrollAnimations = [];
  window.swMouseAnimations = [];
}

window.console.log('%c--- mounted ---', 'color: green');
const vueApp = createApp(App,{
  mounted() {
    [...elem.querySelectorAll('[class*=\'view:\'],[class*=\'fade-in\']')].forEach((el) => {
      if (el.className.indexOf('group-view:') === -1 && !el.dataset.uuid) {
        // console.log('el found', el);
        el.dataset.observed = 'true';
        window.roObserver.add(el, null);
      }
    });
  },
});
vueApp.use(i18n);
vueApp.use(store);
vueApp.use(router);
vueApp.directive('scroll', {
  mounted(el, binding, vnode) {
    window.swobserver.add(el, binding, vnode);
  },
  unmounted(el, binding, vnode) {
    window.swobserver.remove(el, binding, vnode);
  },
});
// console.log('vue mount', elem.innerHTML);
vueApp.mount('#app');
