import { v4 as uuidv4 } from 'uuid';
/**
 * Creates a new IntersectionObserver
 * //
 * Automatically binds and unbinds DOM elements that use the defined keyword after initializing the Class.
 * To activate the Observer - an example:
 * //
 * Vue.prototype.$observer = new SwObserver({ rootMargin: '-150px 0px' });
 * Vue.directive('scroll', {
 *   bind: function (el, binding, vnode) {
 *     vnode.context.$root.$observer.add(el, binding, vnode);
 *   },
 *   unbind: function (el, binding, vnode) {
 *     vnode.context.$root.$observer.remove(el, binding, vnode);
 *   }
 * });
 * //
 * this example creates an observer and binds it to the keyword 'scroll'
 * use this example in the vue component
 * <div v-scroll:in="() => news.visible = true" v-scroll:out="() => news.visible = false" :class="{ 'view': news.visible }">...</div>
 */
export default class SwObserver {
  constructor (options = {}) {
    this.keyName = 'swObserverKey';
    this.watch = {};
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(function (elements) {
        elements.forEach(element => {
          if (this.keyName in element.target.dataset) {
            if (element.target.dataset[this.keyName] in this.watch) {
              const watch = this.watch[element.target.dataset[this.keyName]];
              if (watch.binding.value) {
                if (element.isIntersecting) {
                  if (watch.in) {
                    watch.in();
                  }
                } else {
                  if (watch.out) {
                    watch.out();
                  }
                }
              }
            }
          }
        });
      }.bind(this), options);
    } else {
      this.observer = {
        elements: [],
        scrollTop: -1
      };
    }
  }

  add (el, binding, vnode) {
    // set key
    if (!(this.keyName in vnode)) {
      vnode[this.keyName] = uuidv4();
    }
    // add el to watch object
    if (!(vnode[this.keyName] in this.watch)) {
      el.dataset[this.keyName] = vnode[this.keyName];
      this.watch[vnode[this.keyName]] = {
        el: el,
        binding: binding,
        in: null,
        out: null
      };
      this.watch[vnode[this.keyName]][binding.arg] = binding.value;
      this.observer.observe(el);
    } else {
      this.watch[vnode[this.keyName]][binding.arg] = binding.value;
    }
  }

  remove (el) {
    if (this.keyName in el.dataset) {
      delete this.watch[el.dataset[this.keyName]];
      this.observer.unobserve(el);
    }
  }
}
