import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      teams: [],
      news: [],
      settings: {},
    };
  },
  getters: {
    muted: (state) => {
      return state.muted;
    },
    scrollTop: (state) => {
      return state.scrollTop;
    },
    scrollTopReal: (state) => {
      return state.scrollTopReal;
    },
    winWidth: (state) => {
      return state.winWidth;
    },
    winHeight: (state) => {
      return state.winHeight;
    },
    mouseX: (state) => {
      return state.mouseX;
    },
    mouseY: (state) => {
      return state.mouseY;
    },
    mousePx: (state) => {
      return state.mousePx;
    },
    mousePy: (state) => {
      return state.mousePy;
    },
    menuActive: (state) => {
      return state.menuActive;
    }
  },
  mutations: {
    scroll(state, pos) {
      state.scrollTop = pos;
    },
    scrollReal(state, pos) {
      state.scrollTopReal = pos;
    },
    resize(state) {
      const w = window.innerWidth;
      const h = window.innerHeight;
      if (state.winHeight !== h || state.winWidth !== w) {
        state.winWidth = w;
        state.winHeight = h;
      }
    },
    menuActive(state, val) {
      state.menuActive = val;
    },
    mouse(state, data) {
      state.mouseX = data.x;
      state.mouseY = data.y;
      state.mousePx = data.px;
      state.mousePy = data.py;
    },
    muted(state, data) {
      state.muted = data;
    }
  },
});
export default store;
