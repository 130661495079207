<template>
  <nav>
    <div class="md:hidden flex justify-between items-center mobile-menu">
      <div class="p-5">
        <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }" class="p-0 logo" @click="menuView = false"><img src="/img/MBJC-2023_Logo_RGB_negativ.svg" alt="Mercedes-Benz Junior Cup 2023" class="w-[120px]"></router-link>
      </div>
      <div>
        <div class="p-5" @click="menuView = true">
          <div class="mobile-menu__burger" :class="{ 'view': burgerView }">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-small hidden md:block">
      <div class="flex gap-5 justify-between items-center font-bold">
        <div class="flex justify-start items-center">
          <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }">{{ $t('nav.home') }}</router-link>
          <router-link :to="{ name: 'travel', params: { locale: $i18n.locale } }" v-if="showTravel">{{ $t('nav.travel') }}</router-link>
          <router-link :to="{ name: 'tournament', params: { locale: $i18n.locale } }">{{ $t('nav.games') }}</router-link>
          <router-link :to="{ name: 'history', params: { locale: $i18n.locale } }">{{ $t('nav.info') }}</router-link>
        </div>
        <div class="flex justify-end items-center">
          <a href="/de">DE</a>
          <a href="/en">EN</a>
        </div>
      </div>
    </div>
    <div class="mobile-menu__container mobile-menu" :class="{ 'view': menuView }">
      <div class="mobile-menu-content text-right">
        <router-link :to="{ name: 'home', params: { locale: $i18n.locale } }" @click="menuView = false">{{ $t('nav.home') }}</router-link>
        <router-link :to="{ name: 'travel', params: { locale: $i18n.locale } }" @click="menuView = false">{{ $t('nav.travel') }}</router-link>
        <router-link :to="{ name: 'tournament', params: { locale: $i18n.locale } }" @click="menuView = false">{{ $t('nav.games') }}</router-link>
        <router-link :to="{ name: 'history', params: { locale: $i18n.locale } }" @click="menuView = false">{{ $t('nav.info') }}</router-link>
      </div>
      <div class="absolute" style="top: 7px; right: 7px;">
        <div class="p-5" @click="menuView = false">
          <div class="mobile-menu__close" :class="{ 'view': menuView }">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div class="absolute top-5 left-5 flex items-center">
        <a href="/de">DE</a>
        <a href="/en">EN</a>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'NavComponent',
  components: {},
  props: {
    isHome: Boolean,
  },
  data() {
    return {
      burgerView: false,
      menuView: false,
      showTravel: false
    }
  },
  methods: {},
  mounted() {
    //
    setTimeout(() => {
      this.burgerView = true;
    }, 500);
  },
  unmounted() {
    //
  }
};
</script>

<style lang="scss">
nav {
  @apply md:sticky fixed left-0 right-0;
  top: 0;
  z-index: 500;
  background: #1C1C1C;
  a {
    @apply p-5 text-white transition-all relative;
    display: block;
    &:hover, &.router-link-active {
      @apply text-red-700;
    }
    &.router-link-active {
      @apply bg-red-700 text-white;
    }
    &.logo {
      @apply p-0 bg-none bg-transparent;
    }
  }
  .mobile-menu {
    a {
      padding-right: 32px;
    }
  }
}
</style>
